import React, { useState, useEffect } from 'react'; // Import useEffect
import './BookingModal.css';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux'; // Import useSelector from React-Redux
import axios from 'axios';

const BookingModal = ({
    modalIsOpen,
    closeModal,
    selectedVehicle,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    pickup,
    destination,
    fareDetails,
    pickupCoordinates,
    destinationCoordinates
}) => {
    const { control, handleSubmit, formState: { errors }, setValue, register } = useForm();
    const [paymentMethod, setPaymentMethod] = useState('offline'); // State for payment method
    const user = useSelector((state) => state.auth.user); // Fetch the authenticated user from Redux store

    // Automatically fill user details from the store if modal is open
    useEffect(() => {
        if (modalIsOpen && user) {
            setValue('name', user.name);
            setValue('email', user.email);
            setValue('phone', user.phone);
            setValue('address', user.address);
        }
    }, [user, setValue, modalIsOpen]);

    if (!modalIsOpen) {
        return null;
    }

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toTimeString().split(' ')[0];
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handlePaymentSuccess = (response) => {
        console.log('Payment Successful:', response);
        // Show the payment-success-modal here
        document.querySelector('.payment-success-modal').classList.add('show');
    };

    const handlePayment = async (order) => {
        console.log(order);
        const options = {
            key: 'rzp_test_1WxVEadb1LnUsb', // Replace with your Razorpay key ID
            amount: order.amount * 100,
            currency: order.currency,
            name: 'Your Company Name',
            description: 'Booking Payment',
            order_id: '',
            handler: handlePaymentSuccess,
            prefill: {
                name: user.name,
                email: user.email,
                contact: user.phone,
            },
            notes: {
                address: user.address,
            },
            theme: {
                color: '#F37254',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    const onSubmit = async (data) => {
        const api = axios.create({
            // baseURL: "http://127.0.0.1:8000",
            baseURL: "https://devgas.astergo.in",
        });

        try {
            const formattedDate = formatDate(selectedDate);
            const formattedTime = formatTime(selectedTime);

            const bookingResponse = await api.post('/api/bookings', {
                ...data,
                vehicle_id: selectedVehicle.id,
                booking_date: formattedDate,
                pickup_time: formattedTime,
                pickup_location: pickup,
                dropoff_location: destination,
                customer_id: user.id, // Use the authenticated user's ID
                pickup_lat_long: pickupCoordinates,
                dropoff_lat_long: destinationCoordinates,
                fair_amount: fareDetails.totalFare,
            });

            console.log(bookingResponse);

            const { order } = bookingResponse.data;

            if (paymentMethod === 'online') {
                // Proceed to Razorpay payment
                await handlePayment(order);
            } else {
                // Handle offline payment logic
                console.log('Booking confirmed with offline payment method');
            }

        } catch (error) {
            console.error('Error submitting booking:', error);
        }
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h5>Booking Confirmation</h5>
                    <button onClick={closeModal}>&times;</button>
                </div>

                <section className="booking-section">
                    {/* Booking form content */}

                    <div className="container">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                                <div className="booking-details card shadow-sm p-2 rounded">
                                    <div className="booking-title text-center mb-4">
                                        <h3 className="font-weight-bold text-primary">Booking Details</h3>
                                    </div>
                                    <div className="row booking-info text-center">
                                        <div className="col-md-4 col-sm-6 pickup-address mb-4">
                                            <h5 className="text"><i className="fas fa-map-marker-alt me-2"></i>Pickup</h5>
                                            <p className="font-weight-bold">{pickup}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-6 drop-address mb-4">
                                            <h5 className="text"><i className="fas fa-location-arrow me-2"></i>Drop Off</h5>
                                            <p className="font-weight-bold">{destination}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-6 booking-amount mb-4">
                                            <h5 className="text"><i className="fas fa-money-bill-wave me-2"></i>Amount to be paid</h5>
                                            <h6 className="text-success font-weight-bold"><span>Rs. {fareDetails.totalFare} </span><i className="feather-info"></i></h6>
                                        </div>
                                    </div>
                                    <div className="booking-form">
                                        <div className="booking-title text-center mb-4">
                                            <h5 className="font-weight-bold text-primary">Enter Below Details</h5>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                <div className="input-block">
                                                    <label>Pickup Date</label>
                                                    <Controller
                                                        control={control}
                                                        name="date"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control datetimepicker"
                                                                placeholderText="Select date"
                                                                onChange={(date) => {
                                                                    field.onChange(date);
                                                                    setSelectedDate(date);
                                                                }}
                                                                selected={selectedDate}
                                                                dateFormat="MMMM d, yyyy"
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                    />
                                                    {errors.date && <span>This field is required</span>}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="input-block">
                                                    <label>Pickup Time</label>
                                                    <Controller
                                                        control={control}
                                                        name="time"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control datetimepicker"
                                                                placeholderText="Select time"
                                                                onChange={(time) => {
                                                                    field.onChange(time);
                                                                    setSelectedTime(time);
                                                                }}
                                                                selected={selectedTime}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                    />
                                                    {errors.time && <span>This field is required</span>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input-block">
                                                        <label>Enter Name <span className="text-danger">*</span></label>
                                                        <input type="text" {...register('name', { required: true })} className="form-control" placeholder="Your Name" />
                                                        {errors.name && <span className="error">This field is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input-block">
                                                        <label>Phone Number  <span className="text-danger">*</span></label>
                                                        <input type="text" {...register('phone', { required: true })} className="form-control" placeholder="Phone Number" />
                                                        {errors.phone && <span className="error">This field is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input-block">
                                                        <label>Email Address <span className="text-danger">*</span></label>
                                                        <input type="email" {...register('email', { required: true })} className="form-control" placeholder="Your Email" />
                                                        {errors.email && <span className="error">This field is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input-block">
                                                        <label>Address <span className="text-danger">*</span></label>
                                                        <input type="text" {...register('address', { required: true })} className="form-control" placeholder="Address" />
                                                        {errors.address && <span className="error">This field is required</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="input-block">
                                                        <label>Payment Method <span className="text-danger">*</span></label>
                                                        <select className="form-control" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                                                            <option value="offline">Offline</option>
                                                            <option value="online">Online</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <button type="button" className="btn btn-danger w-100" onClick={closeModal}>Cancel</button>
                                                </div>
                                                <div className="col-lg-6">
                                                    <button type="submit" className="btn btn-success w-100">Confirm Booking</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Payment Success Modal */}
                <div className="payment-success-modal">
                    <h3>Payment Successful!</h3>
                    <p>Your booking has been confirmed.</p>
                    <button onClick={() => document.querySelector('.payment-success-modal').classList.remove('show')}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default BookingModal;
