// src/pages/HomePage.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// import ProductList from '../components/ProductList/ProductList';
import Header from '../components/Main/Header';
// import Hero from '../components/Main/Hero';
// import Search from '../components/Main/Search';
// import Navbar from '../components/Main/Navbar';
// import BookingForm from '../components/Main/BookingForm';
import GeocodeComponent from '../components/Main/GeocodeComponent';
import BookingSummary from '../components/Booking/BookingSummary';
import VehicleListCopy from '../components/Main/Geocode/VehicleListCopy';
// import Map from 'react-map-gl/dist/esm/components/map';

const HomePage = () => {
    const token = useSelector((state) => state.auth.token);

    if (!token) {
        return <Navigate to="/login" />;
    }



    return (
        <div>
            <Header />
            {/* <Hero /> */}
            {/* <Search /> */}
            <GeocodeComponent />
            {/* <Map /> */}
            {/* <BookingForm /> */}
            {/* <h1>Home Page</h1> */}
            {/* <ProductList /> */}
            {/* <Navbar/> */}
            {/* <VehicleListCopy/> */}
            
        </div>
    );
};

export default HomePage;
