// src/components/AuthForm/AuthForm.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser } from '../../features/auth/authSlice';

const AuthForm = ({ isRegister }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isRegister) {
            dispatch(registerUser({ email, password }));
        } else {
            dispatch(loginUser({ email, password }));
        }
    };

    return (
        <>


            <div className="login-wrapper">
                <div className="loginbox">
                    <div className="login-auth">
                        <div className="login-auth-wrap">

                            <h1>Sign In</h1>
                            <p className="account-subtitle">We'll send a confirmation code to your email.</p>
                            <form onSubmit={handleSubmit}>
                                <div className="input-block">
                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} />

                                </div>
                                <div className="input-block">
                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                    <div className="pass-group">
                                        <input type="password" className="form-control pass-input" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <span className="fas fa-eye-slash toggle-password"></span>
                                    </div>
                                </div>
                                <div className="input-block">
                                    <a className="forgot-link" href="forgot-password.html">Forgot Password ?</a>
                                </div>
                                <div className="input-block m-0">
                                    <label className="custom_check d-inline-flex"><span>Remember me</span>
                                        <input type="checkbox" name="remeber" />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-outline-light w-100 btn-size mt-1">{isRegister ? 'Register' : 'Login'}</button>
                                {auth.error && <p>{auth.error}</p>}
                                <div className="login-or">
                                    <span className="or-line"></span>
                                    <span className="span-or-log">Or, log in with your email</span>
                                </div>

                                <div className="text-center dont-have">Don't have an account yet? <a href="#">Register</a></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <form onSubmit={handleSubmit}>
            <div>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <label>Password:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button type="submit">{isRegister ? 'Register' : 'Login'}</button>
            {auth.error && <p>{auth.error}</p>}
        </form> */}
        </>

    );
};

export default AuthForm;
