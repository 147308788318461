import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    distance: null,
    fare: null,
    directionsResponse: null,
    status: 'idle',
    error: null
};

export const bookCar = createAsyncThunk(
    'booking/bookCar',
    async (bookingDetails, { rejectWithValue }) => {
        try {
            const response = await axios.post('https://your-backend-api.com/api/bookings', bookingDetails);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setDistanceAndFare(state, action) {
            state.distance = action.payload.distance;
            state.fare = action.payload.fare;
        },
        setDirectionsResponse(state, action) {
            state.directionsResponse = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bookCar.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(bookCar.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Handle successful booking
            })
            .addCase(bookCar.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setDistanceAndFare, setDirectionsResponse } = bookingSlice.actions;

export default bookingSlice.reducer;
